import { useEffect, useRef, useState } from "react";
import { keyCodes } from "../utils/KeyCodes";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import "overlayscrollbars/overlayscrollbars.css";
import { useClickOutsideElement } from "../utils/customHooks";
import { buildClasses } from "../utils/buildClasses";
import { RyeIcon } from "./RyeIcon";

type DropDownEntryIcon = null | string | JSX.Element;

export type DropDownEntry<TIdType> = {
  id: TIdType;
  text: string;
  icon: DropDownEntryIcon;
};

export function RyeDropDown<TIdType>({
  initEntryId,
  entries,
  onSelectEntry,
  searchable = false,
  showNoneOption = false,
  onSelectNoneOption = () => {},
  onEnter = () => {},
  onEsc = () => {},
  onEntryClick = () => {},
  onClose = () => {},
  size = "md",
  height = "auto",
  width = "md",
  variant = "outlined",
  parentColor = "normal",
  groupHover = false, // add group/ryesearchabledropdown to the parent
  hookInputRef = () => {},
}: {
  initEntryId: TIdType | null;
  entries: DropDownEntry<TIdType>[];
  onSelectEntry: (entry: DropDownEntry<TIdType>) => void;
  searchable?: boolean;
  showNoneOption?: boolean;
  onSelectNoneOption?: () => void;
  onEnter?: (
    event: React.KeyboardEvent<HTMLDivElement>,
    entry: DropDownEntry<TIdType> | null
  ) => void;
  onEsc?: () => void;
  onEntryClick?: () => void;
  onClose?: () => void;
  size?: "sm" | "md" | "lg";
  height?: "auto" | "full";
  width?: "sm" | "md" | "lg" | "full";
  variant?: "outlined" | "transparent";
  parentColor?: "normal" | "purple";
  groupHover?: boolean;
  hookInputRef?: (inputRef: React.RefObject<HTMLInputElement>) => void;
}): JSX.Element {
  const [selectedEntryId, setSelectedEntryId] = useState<TIdType | null>(
    initEntryId
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [highlightIndex, setHighlightIndex] = useState<number | null>(null);
  const expandedContainerRef = useRef<HTMLDivElement>(null);

  function close() {
    setIsExpanded(false);
    setFilterValue("");
    setHighlightIndex(null);
    onClose();
  }

  function selectEntry(entry: DropDownEntry<TIdType>): void {
    setSelectedEntryId(entry.id);
    onSelectEntry(entry);
    close();
  }

  function selectNoneOption() {
    setSelectedEntryId(null);
    onSelectNoneOption();
    close();
  }

  function noneOptionVisible(): boolean {
    return showNoneOption && filterValue === "";
  }

  function handleKeyDown(
    filteredEntries: DropDownEntry<TIdType>[],
    event: React.KeyboardEvent<HTMLDivElement>
  ): void {
    const lastCategoryIndex = filteredEntries.length - 1;
    switch (event.code) {
      case keyCodes.UPARROW:
        event.preventDefault(); // don't want to scroll the transaction row too
        event.stopPropagation();
        if (filteredEntries.length === 0) {
          break;
        } else if (highlightIndex === null || highlightIndex === 0) {
          setHighlightIndex(lastCategoryIndex);
        } else {
          setHighlightIndex(highlightIndex - 1);
        }
        break;
      case keyCodes.DOWNARROW:
        event.preventDefault(); // don't want to scroll the transaction row too
        event.stopPropagation();
        if (filteredEntries.length === 0) {
          break;
        } else if (
          highlightIndex === null ||
          highlightIndex === lastCategoryIndex
        ) {
          setHighlightIndex(0);
        } else {
          setHighlightIndex(highlightIndex + 1);
        }
        break;
      case keyCodes.ENTER:
        event.stopPropagation();
        if (event.repeat) return;
        if (highlightIndex === null) return;
        if (noneOptionVisible() && highlightIndex === 0) {
          selectNoneOption();
          onEnter(event, null);
          return;
        }
        if (
          highlightIndex !== null &&
          highlightIndex < filteredEntries.length
        ) {
          selectEntry(filteredEntries[highlightIndex]);
          onEnter(event, filteredEntries[highlightIndex]);
        } else {
        }
        break;
      case keyCodes.ESC:
        event.stopPropagation();
        if (event.repeat) return;
        close();
        onEsc();
        break;
      case keyCodes.TAB:
        event.stopPropagation();
        if (event.repeat) return;
        close();
        break;
      default:
        break;
    }
  }

  function getSelectedEntry(): DropDownEntry<TIdType> | null {
    const entry = entries.filter((entry) => entry.id === selectedEntryId);
    if (entry.length === 1) {
      return entry[0];
    }
    return null;
  }

  function getHighlightedEntry(
    filteredEntries: DropDownEntry<TIdType>[]
  ): DropDownEntry<TIdType> | null {
    if (highlightIndex === null) {
      return null;
    }
    const entry = filteredEntries.at(highlightIndex);
    if (entry === undefined) {
      return null;
    }
    return entry;
  }

  function hasIcons(): boolean {
    return entries.reduce(
      (hasIcons, entry) => hasIcons || entry.icon !== null,
      false
    );
  }

  function createFilteredEntriesList(): DropDownEntry<TIdType>[] {
    return entries.filter((entry: DropDownEntry<TIdType>) =>
      entry.text.toLowerCase().includes(filterValue.toLowerCase())
    );
  }

  const filteredEntries: DropDownEntry<TIdType>[] = createFilteredEntriesList();

  return (
    <div
      ref={expandedContainerRef}
      className={buildClasses(
        {
          switch: width,
          cases: new Map([
            ["sm", "w-44"],
            ["md", "w-56"],
            ["lg", "w-72"],
            ["full", "w-full"],
          ]),
        },
        { if: height === "full", then: "h-full" },
        "relative"
      )}
      onKeyDown={(e) => handleKeyDown(filteredEntries, e)} // NOTE: this only fires if the input is focused.
    >
      <Input<TIdType>
        selectedEntry={getSelectedEntry()}
        highlightedEntry={getHighlightedEntry(filteredEntries)}
        isExpanded={isExpanded}
        noEntries={filteredEntries.length === 0}
        hasIcons={hasIcons()}
        searchable={searchable}
        size={size}
        height={height}
        variant={variant}
        parentColor={parentColor}
        groupHover={groupHover}
        setFilterValue={setFilterValue}
        setHighlightIndex={setHighlightIndex}
        setIsExpanded={setIsExpanded}
        hookInputRef={hookInputRef}
      />
      {isExpanded ? (
        <Menu<TIdType>
          entries={filteredEntries}
          noneOptionVisible={noneOptionVisible()}
          highlightIndex={highlightIndex}
          hasIcons={hasIcons()}
          size={size}
          setHighlightIndex={setHighlightIndex}
          setIsExpanded={setIsExpanded}
          setSelectedEntryId={setSelectedEntryId}
          selectEntry={selectEntry}
          onEntryClick={onEntryClick}
          selectNoneOption={selectNoneOption}
        ></Menu>
      ) : null}
    </div>
  );
}

function Input<TIdType>(props: {
  selectedEntry: DropDownEntry<TIdType> | null;
  highlightedEntry: DropDownEntry<TIdType> | null;
  isExpanded: boolean;
  noEntries: boolean;
  hasIcons: boolean;
  searchable: boolean;
  size: string;
  height: "auto" | "full";
  variant: "outlined" | "transparent";
  parentColor: "normal" | "purple";
  groupHover: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setHighlightIndex: React.Dispatch<React.SetStateAction<number | null>>;
  hookInputRef: (inputRef: React.RefObject<HTMLInputElement>) => void;
}): JSX.Element {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => props.hookInputRef(inputRef), [props]);

  useEffect(() => {
    if (props.isExpanded) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [props.isExpanded, props.searchable]);

  function handleInputChanged(value: string) {
    if (!props.searchable) {
      return;
    }
    setInputValue(value);
    props.setFilterValue(value);
    props.setHighlightIndex(0);
  }

  function handleFocus() {
    props.setIsExpanded(true);
    setInputValue(getInputValue());
    if (props.searchable) {
      inputRef.current?.select();
    }
  }

  function handleMouseDown(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    if (!props.searchable && props.isExpanded) {
      props.setIsExpanded(false);
      e.preventDefault(); // we don't want to just immediately re-open the drop down by focusing on the click
    }
  }

  function handleIconOrArrowMouseDown(
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) {
    if (!props.searchable && props.isExpanded) {
      props.setIsExpanded(false);
      e.preventDefault(); // we don't want to just immediately re-open the drop down by focusing on the click
    } else if (!props.isExpanded) {
      inputRef.current?.focus();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  function getInputValue(): string {
    if (!props.isExpanded) {
      return props.selectedEntry?.text ?? "";
    }
    return inputValue;
  }

  function getIconValue(): DropDownEntryIcon {
    if (!props.isExpanded) {
      return props.selectedEntry?.icon ?? null;
    }
    if (props.noEntries) {
      return "";
    }
    if (props.highlightedEntry === null && props.selectedEntry !== null) {
      return props.selectedEntry.icon;
    }
    return "";
  }

  return (
    <div
      className={buildClasses(
        { if: props.height === "full", then: "h-full" },
        "w-full",
        "relative",
        "group/ryesearchabledropdowninput"
      )}
    >
      <input
        type="text"
        ref={inputRef}
        className={buildClasses(
          {
            if: !props.searchable,
            then: "caret-transparent",
            else: "focus:cursor-text",
          },
          {
            if: props.noEntries,
            then: buildClasses(
              "bg-red-50",
              "focus:border-red",
              "focus:shadow-red/20"
            ),
            else: buildClasses(
              {
                switch: props.parentColor,
                cases: new Map([
                  ["normal", "bg-transparent"],
                  ["purple", "bg-purple-50"],
                ]),
              },
              "focus:border-purple",
              "focus:shadow-purple-500/20"
            ),
          },
          {
            if: props.hasIcons,
            then: buildClasses("pl-10"),
            else: buildClasses("pl-3"),
          },
          {
            switch: props.size,
            cases: new Map([
              ["sm", buildClasses("text-sm")],
              ["md", buildClasses("text-md")],
              ["lg", buildClasses("text-lg")],
            ]),
          },
          {
            switch: props.height,
            cases: new Map([
              [
                "auto",
                buildClasses({
                  switch: props.size,
                  cases: new Map([
                    ["sm", buildClasses("h-7")],
                    ["md", buildClasses("h-9")],
                    ["lg", buildClasses("h-11")],
                  ]),
                }),
              ],
              ["full", buildClasses("h-full")],
            ]),
          },
          {
            switch: props.variant,
            cases: new Map([
              [
                "outlined",
                buildClasses({
                  switch: props.parentColor,
                  cases: new Map([
                    ["normal", "border-on-surface-300"],
                    ["purple", "border-purple-200"],
                  ]),
                }),
              ],
              [
                "transparent",
                buildClasses(
                  "border-transparent",
                  "focus:border",
                  "hover:border",
                  {
                    switch: props.parentColor,
                    cases: new Map([
                      ["normal", "hover:border-on-surface-200"],
                      ["purple", "hover:border-purple-200"],
                    ]),
                  }
                ),
              ],
            ]),
          },
          {
            if: props.groupHover && !props.isExpanded,
            then: buildClasses(
              "group-hover/ryesearchabledropdown:border",
              "group-hover/ryesearchabledropdown:border-on-surface-200"
            ),
          },
          "outline-none",
          "box-border",
          "w-full",
          "px-3",
          "pr-8",
          "border",
          "text-start",
          "rounded-md",
          "focus:shadow-glow",
          "text-md",
          "overflow-hidden",
          "whitespace-nowrap",
          "text-ellipsis",
          "hover:cursor-pointer",
          "transition-all"
        )}
        value={getInputValue()}
        spellCheck="false"
        onFocus={handleFocus}
        onChange={(e) => handleInputChanged(e.target.value)}
        onMouseDown={(e) => handleMouseDown(e)}
      />
      <EntryIcon
        icon={getIconValue()}
        handleIconOrArrowMouseDown={handleIconOrArrowMouseDown}
      />
      <ArrowIcon
        inputIsFocused={props.isExpanded}
        variant={props.variant}
        groupHover={props.groupHover}
        searchable={props.searchable}
        isExpanded={props.isExpanded}
        setIsExpanded={props.setIsExpanded}
        handleIconOrArrowMouseDown={handleIconOrArrowMouseDown}
      />
    </div>
  );
}

function Menu<TIdType>(props: {
  entries: DropDownEntry<TIdType>[];
  noneOptionVisible: boolean;
  highlightIndex: number | null;
  hasIcons: boolean;
  size: string;
  setHighlightIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedEntryId: React.Dispatch<React.SetStateAction<TIdType | null>>;
  selectEntry: (entry: DropDownEntry<TIdType>) => void;
  onEntryClick: () => void;
  selectNoneOption: () => void;
}): JSX.Element {
  const menuRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (props.highlightIndex !== null) {
      scrollItemIntoView(props.highlightIndex);
    }
  }, [props.highlightIndex]);

  useClickOutsideElement(
    menuRef,
    () => props.setIsExpanded(false),
    true,
    document
  );

  function scrollItemIntoView(itemIndex: number) {
    itemRefs.current[itemIndex]?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      behavior: "smooth",
    });
  }

  return (
    <div ref={menuRef} className={buildClasses("w-full", "absolute", "z-100")}>
      {/* options described at https://kingsora.github.io/OverlayScrollbars/ */}
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "never" },
          overflow: { x: "hidden" },
        }}
        className={buildClasses(
          {
            if: props.entries.length > 0,
            then: buildClasses("border-tiny", "border-on-surface-200"),
            else: buildClasses("border-none"),
          },
          "w-full",
          "overflow-hidden" /* hides extra material from buttons without border radius */,
          "rounded-md",
          "max-h-48",
          "overflow-y-auto",
          "mt-1",
          "shadow-md",
          "z-50"
        )}
      >
        {[
          props.noneOptionVisible ? (
            <MenuItem<string>
              key={"none_option"}
              index={0}
              entry={{
                id: "none_option",
                text: "none",
                icon: "--",
              }}
              isHighlighted={props.highlightIndex === 0}
              isNoneOption={true}
              hasIcons={props.hasIcons}
              itemRefs={itemRefs}
              size={props.size}
              onClick={() => {
                props.selectNoneOption();
                props.onEntryClick(); // callback for any menu item being clicked
              }}
            />
          ) : null,
          ...props.entries.map((entry, i) => (
            <MenuItem<TIdType>
              key={`drop_down_entry_${entry.id}`}
              index={props.noneOptionVisible ? i + 1 : i}
              entry={entry}
              isHighlighted={
                props.noneOptionVisible
                  ? i + 1 === props.highlightIndex
                  : i === props.highlightIndex
              }
              isNoneOption={false}
              hasIcons={props.hasIcons}
              itemRefs={itemRefs}
              size={props.size}
              onClick={() => {
                props.selectEntry(props.entries[i]);
                props.onEntryClick(); // callback for any menu item being clicked
              }}
            />
          )),
        ]}
      </OverlayScrollbarsComponent>
    </div>
  );
}

function MenuItem<TIdType>(props: {
  index: number;
  entry: DropDownEntry<TIdType>;
  isHighlighted: boolean;
  isNoneOption: boolean;
  hasIcons: boolean;
  itemRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  size: string;
  onClick: () => void;
}): JSX.Element {
  return (
    <div
      ref={(ref) => (props.itemRefs.current[props.index] = ref)}
      className={buildClasses(
        {
          if: props.isHighlighted,
          then: buildClasses("bg-on-surface-100"),
          else: buildClasses("bg-surface-50", "hover:bg-on-surface-50"),
        },
        {
          if: props.isNoneOption,
          then: buildClasses("text-on-surface-400"),
        },
        {
          if: props.hasIcons,
          then: buildClasses("pl-10"),
          else: buildClasses("pl-3"),
        },
        {
          switch: props.size,
          cases: new Map([
            ["sm", buildClasses("h-8", "text-sm")],
            ["md", buildClasses("h-9", "text-md")],
            ["lg", buildClasses("h-11", "text-lg")],
          ]),
        },
        "relative",
        "flex",
        "items-center",
        "w-full",
        "px-3",
        "text-left",
        "border-none",
        "cursor-pointer",
        "rounded-none",
        "cursor-pointer"
      )}
      onClick={props.onClick}
      tabIndex={-1}
    >
      <EntryIcon
        icon={props.entry.icon}
        handleIconOrArrowMouseDown={() => {}}
      />
      <div
        className={buildClasses(
          "block",
          "overflow-hidden",
          "whitespace-nowrap",
          "text-ellipsis"
        )}
      >
        {props.entry.text}
      </div>
    </div>
  );
}

function EntryIcon(props: {
  icon: DropDownEntryIcon;
  handleIconOrArrowMouseDown: (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => void;
}): JSX.Element | null {
  if (props.icon === null) {
    return null;
  }
  return (
    <div
      className={buildClasses(
        "absolute",
        "top-1/2",
        "-translate-y-1/2",
        "left-3",
        "z-0",
        "cursor-pointer"
      )}
      onMouseDown={props.handleIconOrArrowMouseDown}
    >
      {props.icon}
    </div>
  );
}

function ArrowIcon(props: {
  inputIsFocused: boolean;
  variant: "outlined" | "transparent";
  groupHover: boolean;
  searchable: boolean;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  handleIconOrArrowMouseDown: (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => void;
}) {
  return (
    <div
      className={buildClasses(
        { if: !props.inputIsFocused, then: "cursor-pointer" },
        { if: props.inputIsFocused, then: "rotate-180" },
        {
          if: props.variant === "transparent" && !props.inputIsFocused,
          then: buildClasses(
            "invisible",
            "group-hover/ryesearchabledropdowninput:visible"
          ),
        },
        {
          if: props.groupHover,
          then: buildClasses("group-hover/ryesearchabledropdown:visible"),
        },
        "cursor-pointer",
        "h-full",
        "flex",
        "items-center",
        "absolute",
        "top-1/2",
        "-translate-y-1/2",
        "right-2",
        "transition-all",
        "select-none"
      )}
      onMouseDown={props.handleIconOrArrowMouseDown}
    >
      <RyeIcon
        name={"keyboard_arrow_down"}
        className={buildClasses("text-on-surface-500")}
      />
    </div>
  );
}
