import { useContext, useEffect, useState } from "react";
import { BreadTransaction, FirestoreDocCategory } from "breadcommon";
import { BreadBudget } from "breadcommon";
import { buildClasses } from "../utils/buildClasses";
import { CategoryGroupsContext } from "../firebaseio/CategoryGroupsContext";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { DollarAmount } from "../common/DollarAmount";
import { TransactionsContext } from "../firebaseio/TransactionsContext";
import dayjs from "dayjs";
import { RyeIcon } from "../rye/RyeIcon";
import { useGetGroupedCategoriesForBudget } from "./useGetGroupedCategoriesForBudget";
import { debounce } from "../utils/debounce";

const CELL_HEIGHT = 2.25; // rem

export function BudgetTrackingTable(props: {
  budget: BreadBudget;
}): JSX.Element {
  const [groupIsExpanded, setGroupIsExpanded] = useState<Map<string, boolean>>(
    new Map()
  );
  const [isScrolledY, setIsScrolledY] = useState(false);
  const [isScrolledX, setIsScrolledX] = useState(false);
  const groupedCategories = useGetGroupedCategoriesForBudget();

  function anyGroupsAreExpanded(): boolean {
    return Array.from(groupIsExpanded.values()).filter((v) => v).length > 0;
  }

  function expandOrCollapseAllGroups() {
    if (anyGroupsAreExpanded()) {
      setGroupIsExpanded((prevState) => {
        const newState = new Map(prevState);
        Array.from(prevState.keys()).forEach((groupId) =>
          newState.set(groupId, false)
        );
        return newState;
      });
    } else {
      setGroupIsExpanded((prevState) => {
        const newState = new Map(prevState);
        Array.from(prevState.keys()).forEach((groupId) =>
          newState.set(groupId, true)
        );
        return newState;
      });
    }
  }

  function setIndividualGroupIsExpanded(
    groupId: string,
    isExpanded: boolean
  ): void {
    setGroupIsExpanded((prevState) => {
      const newState = new Map(prevState);
      newState.set(groupId, isExpanded);
      return newState;
    });
  }

  function handleScroll(event: Event) {
    debounce(() => {
      const target = event.target as HTMLDivElement;
      if (target.scrollTop === 0) {
        setIsScrolledY(false);
      } else {
        setIsScrolledY(true);
      }
      if (target.scrollLeft === 0) {
        setIsScrolledX(false);
      } else {
        setIsScrolledX(true);
      }
    }, 50)();
  }

  const rowGroups: JSX.Element[] = [];
  groupedCategories.forEach((categories, groupId) => {
    if (categories.size > 0) {
      rowGroups.push(
        <RowGroup
          key={`group_${groupId}`}
          groupId={groupId}
          categories={categories}
          budget={props.budget}
          isExpanded={groupIsExpanded.get(groupId) ?? true}
          isScrolledX={isScrolledX}
          setGroupIsExpanded={setIndividualGroupIsExpanded}
        />
      );
    }
  });

  return (
    <div
      className={buildClasses(
        "border",
        "border-surface-500",
        "rounded-lg",
        "w-full",
        "min-h-32",
        "overflow-hidden" // hide non-rounded corners that overflow rounded chart border
      )}
    >
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "move" },
        }}
        className={buildClasses("h-full")}
        events={{
          scroll: (_instance, event) => {
            handleScroll(event);
          },
        }}
      >
        <HeaderRow
          budget={props.budget}
          anyGroupsAreExpanded={anyGroupsAreExpanded()}
          isScrolledY={isScrolledY}
          isScrolledX={isScrolledX}
          expandOrCollapseAllGroups={expandOrCollapseAllGroups}
        />
        <UnreviewedRow budget={props.budget} isScrolledX={isScrolledX} />
        {rowGroups}
      </OverlayScrollbarsComponent>
    </div>
  );
}

function HeaderRow(props: {
  budget: BreadBudget;
  anyGroupsAreExpanded: boolean;
  isScrolledY: boolean;
  isScrolledX: boolean;
  expandOrCollapseAllGroups: () => void;
}) {
  return (
    <div
      className={buildClasses(
        { if: props.isScrolledY, then: buildClasses("clipped-shadow-bottom") },
        "min-w-full",
        "inline-flex", // displaying inline makes width the width work best (fits width of the contents instead of stopping at width of parent)
        "sticky",
        "top-0",
        "bg-surface",
        "z-20",
        "rounded-t-lg",
        "border-b",
        "border-surface-600",
        "font-medium"
      )}
    >
      <Cell width="lg" freezeIndex={0} align={"left"} bgColor="header">
        <div
          className={buildClasses(
            "w-full",
            "flex",
            "items-center",
            "justify-between",
            "cursor-pointer"
          )}
          onClick={props.expandOrCollapseAllGroups}
        >
          Category
          <RyeIcon
            name={props.anyGroupsAreExpanded ? "collapse_all" : "expand_all"}
            size="sm"
            className={buildClasses("select-none")}
          />
        </div>
      </Cell>
      <Cell width="md" freezeIndex={1} bgColor="header">
        Total Budgeted
      </Cell>
      <Cell width="md" freezeIndex={2} bgColor="header">
        Budgeted to Date
      </Cell>
      <Cell
        width="md"
        freezeIndex={3}
        bgColor="header"
        showRightShadow={props.isScrolledX}
      >
        Actuals to Date
      </Cell>
      {calculateMonthly(props.budget, (startOfMonth) =>
        startOfMonth.format("MMM 'YY")
      ).map((monthHeader, i) => (
        <Cell key={`table_header_month_${i}`} bgColor="header">
          {monthHeader}
        </Cell>
      ))}
    </div>
  );
}

function RowGroup(props: {
  groupId: string;
  categories: Map<string, FirestoreDocCategory>;
  budget: BreadBudget;
  isExpanded: boolean;
  isScrolledX: boolean;
  setGroupIsExpanded: (groupId: string, isExpanded: boolean) => void;
}) {
  useEffect(
    () => props.setGroupIsExpanded(props.groupId, props.isExpanded),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function getHeight(): string {
    if (props.isExpanded) {
      return `${props.categories.size * CELL_HEIGHT}rem`;
    }
    return "0px";
  }

  return (
    <div
      className={buildClasses(
        "inline-flex",
        "flex-col",
        "border-b",
        "border-surface-600",
        "last:border-none",
        "min-w-full"
      )}
    >
      <GroupHeaderRow
        groupId={props.groupId}
        categories={props.categories}
        budget={props.budget}
        isExpanded={props.isExpanded}
        isScrolledX={props.isScrolledX}
        setGroupIsExpanded={props.setGroupIsExpanded}
      />
      <div
        className={buildClasses(
          "transition-height",
          "min-w-full",
          "inline-block",
          "overflow-clip"
        )}
        style={{ height: getHeight() }}
      >
        {Array.from(props.categories.values()).map((category) => (
          <GroupMemberRow
            key={`group_member_${category.id}`}
            category={category}
            budget={props.budget}
            isScrolledX={props.isScrolledX}
          />
        ))}
      </div>
    </div>
  );
}

function GroupHeaderRow(props: {
  groupId: string;
  categories: Map<string, FirestoreDocCategory>;
  budget: BreadBudget;
  isExpanded: boolean;
  isScrolledX: boolean;
  setGroupIsExpanded: (groupId: string, isExpanded: boolean) => void;
}) {
  const categoryGroups = useContext(CategoryGroupsContext);
  const transactions = useContext(TransactionsContext);
  const group = categoryGroups.get(props.groupId)!;
  const transactionsForGroup = Array.from(transactions.values()).filter(
    (transaction) =>
      transaction.reviewed &&
      transaction.categoryId !== null &&
      props.categories.has(transaction.categoryId)
  );

  function getTotalBudgeted() {
    return Array.from(props.categories).reduce((acc, [categoryId]) => {
      if (props.budget.line_items.has(categoryId)) {
        return (acc += props.budget.line_items.get(categoryId)!.amount);
      }
      return acc;
    }, 0);
  }

  function getBudgetedToDate() {
    return getTotalBudgeted() * getFractionOfTimeIntoBudget(props.budget);
  }

  return (
    <div className={buildClasses("inline-flex", "min-w-full", "font-normal")}>
      <Cell width="lg" freezeIndex={0} align={"left"} bgColor="subheader">
        <div
          className={buildClasses(
            "w-full",
            "flex",
            "items-center",
            "justify-between",
            "cursor-pointer"
          )}
          onClick={() =>
            props.setGroupIsExpanded(props.groupId, !props.isExpanded)
          }
        >
          <div
            className={buildClasses(
              "w-full",
              "whitespace-pre",
              "overflow-hidden",
              "whitespace-nowrap",
              "text-ellipsis",
              "mr-1"
            )}
          >
            {group.name}
          </div>
          <RyeIcon
            name={"keyboard_arrow_up"}
            className={buildClasses(
              { if: props.isExpanded, then: "rotate-180" },
              "select-none",
              "transition-all"
            )}
            size="sm"
          />
        </div>
      </Cell>
      <Cell width="md" freezeIndex={1} isDollarAmount bgColor="subheader">
        {getTotalBudgeted()}
      </Cell>
      <Cell width="md" freezeIndex={2} isDollarAmount bgColor="subheader">
        {getBudgetedToDate()}
      </Cell>
      <Cell
        width="md"
        freezeIndex={3}
        isDollarAmount
        bgColor="subheader"
        showRightShadow={props.isScrolledX}
      >
        {getActualsToDate(transactionsForGroup, props.budget)}
      </Cell>
      {getMonthlyActuals(transactionsForGroup, props.budget).map((total, i) => (
        <Cell
          key={`group_header_monthly_total_${i}`}
          isDollarAmount
          bgColor="subheader"
        >
          {total}
        </Cell>
      ))}
    </div>
  );
}

function GroupMemberRow(props: {
  category: FirestoreDocCategory;
  budget: BreadBudget;
  isScrolledX: boolean;
}) {
  const transactions = useContext(TransactionsContext);
  const transactionsForCategory = Array.from(transactions.values()).filter(
    (transaction) =>
      transaction.reviewed && transaction.categoryId === props.category.id
  );

  function getTotalBudgeted() {
    const budgeted = props.budget.line_items.get(props.category.id);
    if (budgeted !== undefined) {
      return budgeted.amount;
    }
    return 0;
  }

  function getBudgetedToDate() {
    return getTotalBudgeted() * getFractionOfTimeIntoBudget(props.budget);
  }

  return (
    <div className={buildClasses("inline-flex", "min-w-full")}>
      <Cell width="lg" freezeIndex={0} align={"left"}>
        <div
          className={buildClasses(
            "w-full",
            "whitespace-pre",
            "overflow-hidden",
            "whitespace-nowrap",
            "text-ellipsis"
          )}
        >{`   ${props.category.emoji}   ${props.category.name}`}</div>
      </Cell>
      <Cell width="md" freezeIndex={1} isDollarAmount>
        {getTotalBudgeted()}
      </Cell>
      <Cell width="md" freezeIndex={2} isDollarAmount>
        {getBudgetedToDate()}
      </Cell>
      <Cell
        width="md"
        freezeIndex={3}
        isDollarAmount
        showRightShadow={props.isScrolledX}
      >
        {getActualsToDate(transactionsForCategory, props.budget)}
      </Cell>
      {getMonthlyActuals(transactionsForCategory, props.budget).map(
        (total, i) => (
          <Cell key={`group_member_monthly_total_${i}`} isDollarAmount>
            {total}
          </Cell>
        )
      )}
    </div>
  );
}

function UnreviewedRow(props: {
  budget: BreadBudget;
  isScrolledX: boolean;
}): JSX.Element | null {
  const transactions = useContext(TransactionsContext);
  const unreviewedTransactions = Array.from(transactions.values()).filter(
    (transaction) => !transaction.reviewed
  );

  if (getActualsToDate(unreviewedTransactions, props.budget) === 0) {
    return null;
  }

  return (
    <div
      className={buildClasses(
        "inline-flex",
        "min-w-full",
        "text-yellow-600",
        "border-b",
        "border-surface-600"
      )}
    >
      <Cell width="lg" freezeIndex={0} align={"left"}>
        Unreviewed
      </Cell>
      <Cell width="md" freezeIndex={1} isDollarAmount>
        {0}
      </Cell>
      <Cell width="md" freezeIndex={2} isDollarAmount>
        {0}
      </Cell>
      <Cell
        width="md"
        freezeIndex={3}
        isDollarAmount
        showRightShadow={props.isScrolledX}
      >
        {getActualsToDate(unreviewedTransactions, props.budget)}
      </Cell>
      {getMonthlyActuals(unreviewedTransactions, props.budget).map((total) => (
        <Cell isDollarAmount>{total}</Cell>
      ))}
    </div>
  );
}

function Cell({
  width = "sm",
  freezeIndex = null,
  isDollarAmount = false,
  align = "right",
  bgColor = "normal",
  showRightShadow = false,
  children,
}: {
  width?: "sm" | "md" | "lg";
  freezeIndex?: number | null;
  isDollarAmount?: true | false;
  align?: "left" | "right";
  bgColor?: "normal" | "header" | "subheader" | "warning";
  showRightShadow?: boolean;
  children: React.ReactNode;
}) {
  return (
    <div
      className={buildClasses(
        {
          switch: width,
          cases: new Map([
            ["sm", buildClasses("w-32")],
            ["md", buildClasses("w-40")],
            ["lg", buildClasses("w-60")],
          ]),
        },
        {
          if: freezeIndex !== null,
          then: buildClasses("sticky", "border-r", "border-surface-600"),
        },
        {
          if: freezeIndex === 0,
          then: buildClasses("left-0"),
          elseIfs: [
            {
              elseIf: freezeIndex === 1,
              then: buildClasses("left-60"),
            },
            {
              elseIf: freezeIndex === 2,
              then: buildClasses("left-[25rem]"),
            },
            {
              elseIf: freezeIndex === 3,
              then: buildClasses("left-[35rem]"),
            },
          ],
        },
        {
          switch: align,
          cases: new Map([
            ["left", "justify-start"],
            ["right", "justify-end"],
          ]),
        },
        {
          switch: bgColor,
          cases: new Map([
            ["normal", "bg-surface-50"],
            ["header", "bg-surface-50"],
            ["subheader", "bg-surface-300"],
            ["warning", "bg-yellow-200"],
          ]),
        },
        { if: showRightShadow, then: "clipped-shadow-right" },
        "flex",
        "items-center",
        "flex-shrink-0",
        "flex-grow",
        "px-4",
        "z-100",
        "overflow-hidden",
        "whitespace-nowrap"
      )}
      style={{ height: `${CELL_HEIGHT}rem` }}
    >
      {isDollarAmount && typeof children === "number" ? (
        <DollarAmount
          n={children}
          align={align}
          showMinus={true}
          showPlus={true}
        />
      ) : (
        children
      )}
    </div>
  );
}

function getFractionOfTimeIntoBudget(budget: BreadBudget) {
  const millisFullBudgetDuration =
    budget.endDateTimestampSecs.valueOf() -
    budget.beginDateTimestampSecs.valueOf();

  const millisIntoBudget = Math.min(
    Math.max(dayjs().valueOf() - budget.beginDateTimestampSecs.valueOf(), 0),
    millisFullBudgetDuration
  );

  return millisIntoBudget / millisFullBudgetDuration;
}

function getActualsToDate(
  filteredTransactions: BreadTransaction[],
  budget: BreadBudget
): number {
  return filteredTransactions
    .filter(
      (transaction) =>
        transaction.date >= budget.beginDateTimestampSecs &&
        transaction.date <= budget.endDateTimestampSecs
    )
    .reduce((acc, transaction) => acc + transaction.amount, 0);
}

function getMonthlyActuals(
  filteredTransactions: BreadTransaction[],
  budget: BreadBudget
): number[] {
  return calculateMonthly(budget, (startOfMonth) => {
    return sumTransactions(
      filterTransactionsToMonth(filteredTransactions, startOfMonth)
    );
  });
}

function calculateMonthly<T>(
  budget: BreadBudget,
  action: (startOfMonth: dayjs.Dayjs) => T
): T[] {
  const calculations: T[] = [];
  for (
    let d = budget.beginDateTimestampSecs.startOf("month");
    d < budget.endDateTimestampSecs;
    d = d.add(1, "month")
  ) {
    calculations.push(action(d));
  }
  return calculations;
}

function sumTransactions(transactions: BreadTransaction[]): number {
  return transactions.reduce((acc, transaction) => acc + transaction.amount, 0);
}

function filterTransactionsToMonth(
  transactions: BreadTransaction[],
  month: dayjs.Dayjs
): BreadTransaction[] {
  return transactions.filter(
    (transaction) =>
      transaction.date >= month.startOf("month") &&
      transaction.date <= month.endOf("month")
  );
}
