import { buildClasses } from "../utils/buildClasses";
import { RuleHighlighter } from "./RuleHighlighter";
import { TRANSACTION_CELL_PADDING_X } from "./TransactionColumnSizer";

export function DefaultTransactionColumnValue({
  align = "LEFT",
  ruleHighlight = false,
  children,
}: {
  ruleHighlight?: boolean;
  align?: "LEFT" | "RIGHT";
  children?: React.ReactNode;
}) {
  return (
    <RuleHighlighter ruleHighlight={ruleHighlight} color={"purple"}>
      <div
        className={buildClasses(
          {
            switch: align,
            cases: new Map([
              ["LEFT", "justify-start"],
              ["RIGHT", "justify-end"],
            ]),
          },
          "w-full",
          "h-full",
          "flex",
          "items-center",
          "rounded-md",
          TRANSACTION_CELL_PADDING_X
        )}
      >
        {children}
      </div>
    </RuleHighlighter>
  );
}
