import { FirestoreDocCategory, FirestoreDocCategoryGroup } from "breadcommon";
import { useContext } from "react";
import {
  firestoreCreateCategory,
  firestoreUpdateCategoryGroup,
} from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { RyeEditableText } from "../rye/RyeEditableText";
import { buildClasses } from "../utils/buildClasses";
import { RyeButton } from "../rye/RyeButton";
import {
  FocusCategoryGroupIndexContext,
  FocusCategoryIndexContext,
} from "./CategoriesScreen";
import dayjs from "dayjs";
import { CategoriesContext } from "../firebaseio/CategoriesContext";

export function CategoryGroupItem({
  categoryGroup,
  openDeleteCategoryGroupModal,
}: {
  categoryGroup: FirestoreDocCategoryGroup;
  openDeleteCategoryGroupModal: () => void;
}): JSX.Element {
  const user = useContext(UserContext);
  const [focusCategoryGroupIndex, setFocusCategoryGroupIndex] = useContext(
    FocusCategoryGroupIndexContext
  );
  const [, setFocusCategoryIndex] = useContext(FocusCategoryIndexContext);
  const categories = useContext(CategoriesContext);

  function updateCategoryGroupName(newName: string) {
    firestoreUpdateCategoryGroup(user.uid, categoryGroup.id, {
      name: newName,
    });
  }

  const numCategoriesInGroup = Array.from(categories.values()).filter(
    (category: FirestoreDocCategory) =>
      category.category_group_id === categoryGroup.id
  ).length;

  function createCategory() {
    firestoreCreateCategory(user.uid, {
      type: "Expense",
      name: "New Category",
      created_timestamp_secs: dayjs().unix(),
      order_position: numCategoriesInGroup,
      emoji: "💰",
      category_group_id: categoryGroup.id,
    });
    setFocusCategoryIndex({
      groupId: categoryGroup.id,
      categoryIndex: numCategoriesInGroup,
    });
  }

  return (
    <div className={buildClasses("w-full", "flex", "items-center")}>
      <RyeEditableText
        startingText={categoryGroup.name}
        save={updateCategoryGroupName}
        placeholderText="Add a group name"
        size="lg"
        fontWeight="heavy"
        width="xl"
        focus={focusCategoryGroupIndex === categoryGroup.order_position}
        ackFocus={() => setFocusCategoryGroupIndex(null)}
      />
      <div className={buildClasses("w-20", "flex-shrink-0", "flex-grow")} />
      <RyeButton
        size="sm"
        icon="add"
        text="Add category"
        variant="outlined"
        vibe="subdued"
        onClick={createCategory}
      />
      <div className={buildClasses("w-5", "flex-shrink-0")} />
      <div
        className={buildClasses(
          "invisible",
          "group-hover/categorygroupitem:visible"
        )}
      >
        <RyeButton
          size="sm"
          icon="delete"
          variant="transparent"
          vibe="subdued"
          onClick={openDeleteCategoryGroupModal}
        />
      </div>
    </div>
  );
}
