import { useState } from "react";
import { RyeButton } from "../rye/RyeButton";
import { RyeMainContentCard } from "../rye/RyeMainContentCard";
import { RyeMainContentHeader } from "../rye/RyeMainContentHeader";
import { RyeMainContentSpacer } from "../rye/RyeMainContentSpacer";
import { buildClasses } from "../utils/buildClasses";
import { AddAccountModal } from "./AddAccountModal";
import { AccountsList } from "./AccountsList";
import { Balances } from "./Balances";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";

export function AccountsScreen(): JSX.Element {
  const [addAccountModalIsOpen, setAddAccountModalIsOpen] =
    useState<boolean>(false);

  return (
    <div
      className={buildClasses(
        "w-full",
        "h-full",
        "flex",
        "flex-col",
        "flex-grow",
        "flex-shrink",
        "min-w-1"
      )}
    >
      <RyeMainContentHeader>
        <RyeButton
          text="Add Account"
          icon="add"
          size="sm"
          variant="outlined"
          vibe="subdued"
          shadow="sm"
          onClick={() => setAddAccountModalIsOpen(true)}
        />
        {addAccountModalIsOpen ? (
          <AddAccountModal
            isOpen={addAccountModalIsOpen}
            setIsOpen={setAddAccountModalIsOpen}
          ></AddAccountModal>
        ) : null}
      </RyeMainContentHeader>
      <RyeMainContentSpacer direction={"h"} />
      <div className={buildClasses("flex", "flex-grow")}>
        <RyeMainContentCard
          roundedTl={false}
          roundedTr={false}
          roundedBr={false}
        >
          <div className={buildClasses("min-w-[840px]")}>
            <RyeMainContentTitle title={"Accounts"} />
            <AccountsList />
          </div>
        </RyeMainContentCard>
        <RyeMainContentSpacer direction={"v"} />
        <RyeMainContentCard
          flexGrow={false}
          flexShrink={false}
          roundedTl={false}
          roundedTr={false}
          roundedBl={false}
        >
          <RyeMainContentTitle title={"Totals"} />
          <div className="h-4" />
          <Balances />
        </RyeMainContentCard>
      </div>
    </div>
  );
}
