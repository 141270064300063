import { buildClasses } from "../utils/buildClasses";
import { useEffect, useRef, useState } from "react";
import { keyCodes } from "../utils/KeyCodes";

export function RyeEditableText({
  startingText,
  save,
  placeholderText = "",
  size = "md",
  width = "md",
  height = "auto",
  fontWeight = "normal",
  groupHover = false, // add group/ryeeditabletext to the parent
  focus = false,
  ackFocus = () => {},
}: {
  startingText: string;
  save: (newText: string) => void;
  placeholderText?: string;
  size?: "sm" | "md" | "lg";
  height?: "auto" | "full";
  width?: "sm" | "md" | "lg" | "xl" | "full";
  fontWeight?: "light" | "normal" | "heavy";
  groupHover?: boolean;
  focus?: boolean;
  ackFocus?: () => void;
}): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState(startingText);
  const inputRef = useRef<HTMLInputElement>(null);

  // Keep the text in sync with any changes to the input text. One far-fetched
  // reason to make sure we do this is in case someone changes the value
  // on a different device.
  useEffect(() => setText(startingText), [startingText]);

  useEffect(() => {
    if (focus) {
      inputRef.current?.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
      inputRef.current?.select(); // this takes care of focusing too!
      ackFocus();
    }
  }, [ackFocus, focus]);

  function handleOnKeyUp(e: React.KeyboardEvent<HTMLInputElement>): void {
    switch (e.code) {
      case keyCodes.ESC:
        inputRef.current?.blur();
        break;
      case keyCodes.ENTER:
        inputRef.current?.blur();
    }
  }

  function handleBlur() {
    save(text);
    setIsFocused(false);
  }

  function handleFocus() {
    setIsFocused(true);
  }

  return (
    <input
      ref={inputRef}
      placeholder={placeholderText}
      className={buildClasses(
        {
          switch: size,
          cases: new Map([
            ["sm", buildClasses("text-sm")],
            ["md", buildClasses("text-md")],
            ["lg", buildClasses("text-lg")],
          ]),
        },
        {
          switch: height,
          cases: new Map([
            [
              "auto",
              buildClasses({
                switch: size,
                cases: new Map([
                  ["sm", buildClasses("h-7")],
                  ["md", buildClasses("h-9")],
                  ["lg", buildClasses("h-11")],
                ]),
              }),
            ],
            ["full", buildClasses("h-full")],
          ]),
        },
        {
          switch: width,
          cases: new Map([
            ["sm", "w-44"],
            ["md", "w-56"],
            ["lg", "w-72"],
            ["xl", "w-96"],
            ["full", "w-full"],
          ]),
        },
        {
          switch: fontWeight,
          cases: new Map([
            ["light", buildClasses("font-light")],
            ["normal", buildClasses("font-normal")],
            ["heavy", buildClasses("font-medium")],
          ]),
        },
        {
          if: groupHover && !isFocused,
          then: buildClasses(
            "group-hover/ryeeditabletext:border",
            "group-hover/ryeeditabletext:border-on-surface-200"
          ),
        },
        "px-2",
        "bg-transparent",
        "border",
        "border-transparent",
        "rounded-md",
        "hover:border-on-surface-200",
        "focus:border-purple",
        "focus:shadow-glow",
        "focus:shadow-purple-100",
        "outline-none",
        "transition-all"
      )}
      value={text}
      onChange={(e) => setText(e.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyUp={(e) => handleOnKeyUp(e)}
    ></input>
  );
}
