import { useContext } from "react";

import "overlayscrollbars/overlayscrollbars.css";
import { firestoreUpdateTransaction } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { TransactionColumnSizer } from "./TransactionColumnSizer";
import { TransactionColumn } from "./TransactionColumn";
import { DropDownEntry, RyeDropDown } from "../rye/RyeDropDown";
import { RuleHighlighter } from "./RuleHighlighter";
import { TransactionRowContext } from "./TransactionRow";

export function TransactionColumnValueCategory(props: {
  transactionId: string;
  categoryId: string | null;
  createNewRule: (categoryIdAction: string) => void;
  focusTransaction: () => void;
  selectPreviousTransaction: () => void;
  selectNextTransaction: () => void;
  saveDropDownRef: (inputRef: React.RefObject<HTMLInputElement>) => void;
}): JSX.Element {
  const user = useContext(UserContext);
  const categories = useContext(CategoriesContext);
  const { transactionSelected, ruleForFieldHighlighting } = useContext(
    TransactionRowContext
  );

  function updateTransactionCategory(newCategoryId: string) {
    firestoreUpdateTransaction(
      user.uid,
      props.transactionId,
      {
        manual_data: {
          category_id: newCategoryId,
        },
      },
      null
    );
  }

  function clearTransactionCategory() {
    firestoreUpdateTransaction(
      user.uid,
      props.transactionId,
      {
        manual_data: {
          category_id: null,
        },
      },
      null
    );
  }

  function markTransactionReviewed(): void {
    firestoreUpdateTransaction(
      user.uid,
      props.transactionId,
      {
        manual_data: { reviewed: true },
      },
      null
    );
  }

  function handleEnter(
    event: React.KeyboardEvent<HTMLDivElement>,
    entry: DropDownEntry<string> | null
  ) {
    if (entry !== null) {
      markTransactionReviewed();
    }
    if (entry !== null && (event.ctrlKey || event.metaKey)) {
      props.createNewRule(entry.id);
    }
    if (event.shiftKey) {
      props.selectPreviousTransaction();
    } else {
      props.selectNextTransaction();
    }
  }

  function ruleHighlight(): boolean {
    return (
      ruleForFieldHighlighting.get !== null &&
      ruleForFieldHighlighting.get.action.category_id !== null
    );
  }

  return (
    <TransactionColumnSizer column={TransactionColumn.CATEGORY}>
      <RuleHighlighter ruleHighlight={ruleHighlight()} color={"green"}>
        <RyeDropDown<string>
          initEntryId={props.categoryId}
          entries={Array.from(categories).map(([id, category]) => {
            return {
              id: id,
              text: category.name,
              icon: category.emoji,
            };
          })}
          onSelectEntry={(entry) => updateTransactionCategory(entry.id)}
          searchable
          showNoneOption
          onSelectNoneOption={() => clearTransactionCategory()}
          width="full"
          height="full"
          variant={transactionSelected ? "outlined" : "transparent"}
          parentColor={transactionSelected ? "purple" : "normal"}
          onEnter={handleEnter}
          onEsc={props.focusTransaction} // this serves two purposes - it keeps the row highlighted when we push escape and it ensures when we go elsewhere the row went back to being focused so it correctly blurs and unhighlights
          onEntryClick={props.focusTransaction}
          hookInputRef={props.saveDropDownRef}
        />
      </RuleHighlighter>
    </TransactionColumnSizer>
  );
}
