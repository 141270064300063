import { useState, useRef, useEffect } from "react";
import { buildClasses } from "../utils/buildClasses";
import { keyCodes } from "../utils/KeyCodes";
import { RyeIcon } from "./RyeIcon";

export function RyeSearchBar({
  search,
  startingText = "",
  placeholderText = "",
  size = "md",
  height = "auto",
  width = "md",
  focus = false,
  ackFocus = () => {},
}: {
  search: (searchText: string) => void;
  startingText?: string;
  placeholderText?: string;
  size?: "sm" | "md" | "lg";
  height?: "auto" | "full";
  width?: "sm" | "md" | "lg" | "xl" | "full";
  focus?: boolean;
  ackFocus?: () => void;
}): JSX.Element {
  const [text, setText] = useState(startingText);
  const inputRef = useRef<HTMLInputElement>(null);

  // Keep the text in sync with any changes to the input text. One far-fetched
  // reason to make sure we do this is in case someone changes the value
  // on a different device.
  useEffect(() => setText(startingText), [startingText]);

  useEffect(() => {
    if (focus) {
      inputRef.current?.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
      inputRef.current?.select(); // this takes care of focusing too!
      ackFocus();
    }
  }, [ackFocus, focus]);

  function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.repeat) {
      return;
    }
    switch (e.code) {
      case keyCodes.ESC:
        inputRef.current?.blur();
        break;
      case keyCodes.ENTER:
        inputRef.current?.blur();
        search(text);
        break;
    }
  }

  function handleInputChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
    search(e.target.value);
  }

  return (
    <div
      className={buildClasses(
        { if: width === "full", then: "w-full" },
        "flex",
        "items-center",
        "relative"
      )}
    >
      <RyeIcon
        name={"search"}
        size="sm"
        className={buildClasses("absolute", "left-3")}
      />
      <input
        ref={inputRef}
        placeholder={placeholderText}
        className={buildClasses(
          {
            switch: size,
            cases: new Map([
              ["sm", buildClasses("text-sm")],
              ["md", buildClasses("text-md")],
              ["lg", buildClasses("text-lg")],
            ]),
          },
          {
            switch: height,
            cases: new Map([
              [
                "auto",
                buildClasses({
                  switch: size,
                  cases: new Map([
                    ["sm", buildClasses("h-7")],
                    ["md", buildClasses("h-9")],
                    ["lg", buildClasses("h-11")],
                  ]),
                }),
              ],
              ["full", buildClasses("h-full")],
            ]),
          },
          {
            switch: width,
            cases: new Map([
              ["sm", "w-44"],
              ["md", "w-56"],
              ["lg", "w-72"],
              ["xl", "w-96"],
              ["full", "w-full"],
            ]),
          },
          "px-3",
          "pl-10",
          "bg-on-surface-100",
          "border",
          "border-transparent",
          "rounded-full",
          "hover:border-on-surface-200",
          "focus:border-purple",
          "focus:shadow-glow",
          "focus:shadow-purple-100",
          "outline-none",
          "transition-all"
        )}
        value={text}
        onChange={(e) => handleInputChanged(e)}
        onKeyDown={(e) => handleOnKeyDown(e)}
      />
    </div>
  );
}
