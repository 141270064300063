import { useState } from "react";
import { RyeMainContentCard } from "../rye/RyeMainContentCard";
import { RyeMainContentSpacer } from "../rye/RyeMainContentSpacer";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";
import { buildClasses } from "../utils/buildClasses";
import { GeneralSettings } from "./GeneralSettings";
import { BillingSettings } from "./BillingSettings";
import { ProfileSettings } from "./ProfileSettings";
import { RyeIcon } from "../rye/RyeIcon";
import { getAuth, signOut } from "firebase/auth";
import { RyeButton } from "../rye/RyeButton";

enum SettingsTab {
  PREFERENCES,
  BILLING,
  PROFILE,
}

export function SettingsScreen(): JSX.Element {
  const [currentTab, setCurrentTab] = useState<SettingsTab>(
    SettingsTab.PREFERENCES
  );

  function getSettingsTab(): JSX.Element {
    switch (currentTab) {
      case SettingsTab.PROFILE:
        return <ProfileSettings />;
      case SettingsTab.PREFERENCES:
        return <GeneralSettings />;
      case SettingsTab.BILLING:
        return <BillingSettings />;
    }
  }

  async function logOut() {
    const auth = getAuth();
    await signOut(auth);
  }

  return (
    <div className={buildClasses("flex", "w-full")}>
      <RyeMainContentCard
        roundedTr={false}
        roundedBr={false}
        flexGrow={false}
        flexShrink={false}
        padBottom={false}
      >
        <div
          className={buildClasses(
            "flex",
            "flex-col",
            "h-full",
            "justify-between",
            "py-6"
          )}
        >
          <div>
            <TabButton
              text={tabEnumToText(SettingsTab.PREFERENCES)}
              iconName="tune"
              isSelected={currentTab === SettingsTab.PREFERENCES}
              handleClick={() => setCurrentTab(SettingsTab.PREFERENCES)}
            />
            <TabSpacer />
            <TabButton
              text={tabEnumToText(SettingsTab.BILLING)}
              iconName="credit_card"
              isSelected={currentTab === SettingsTab.BILLING}
              handleClick={() => setCurrentTab(SettingsTab.BILLING)}
            />
            <TabSpacer />
            <TabButton
              text={tabEnumToText(SettingsTab.PROFILE)}
              iconName="person"
              isSelected={currentTab === SettingsTab.PROFILE}
              handleClick={() => setCurrentTab(SettingsTab.PROFILE)}
            />
          </div>
          <RyeButton
            text="Sign out"
            icon="logout"
            onClick={logOut}
            vibe="subdued"
          />
        </div>
      </RyeMainContentCard>
      <RyeMainContentSpacer direction={"v"} />
      <RyeMainContentCard roundedTl={false} roundedBl={false}>
        <RyeMainContentTitle title={tabEnumToText(currentTab)} />
        {getSettingsTab()}
      </RyeMainContentCard>
    </div>
  );
}

function tabEnumToText(tab: SettingsTab): string {
  switch (tab) {
    case SettingsTab.PREFERENCES:
      return "Preferences";
    case SettingsTab.BILLING:
      return "Billing";
    case SettingsTab.PROFILE:
      return "Profile";
  }
}

function TabButton({
  text,
  iconName,
  isSelected,
  handleClick,
}: {
  text: string;
  iconName: string;
  isSelected: boolean;
  handleClick: () => void;
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        {
          if: isSelected,
          then: buildClasses("text-on-surface-800", "bg-on-surface-200"),
          else: buildClasses(
            "text-on-surface-500",
            "hover:text-on-surface-800"
          ),
        },
        "flex",
        "items-center",
        "cursor-pointer",
        "px-3",
        "py-1",
        "rounded-md",
        "select-none"
      )}
      onClick={handleClick}
    >
      <RyeIcon
        name={iconName}
        size="sm"
        fill={isSelected}
        className={buildClasses("mr-2", "ml-[-0.1rem]")}
      />
      {text}
    </div>
  );
}

function TabSpacer(): JSX.Element {
  return <div className="h-2" />;
}
