import { useContext } from "react";
import { DollarAmount } from "../common/DollarAmount";
import { AccountsContext } from "../firebaseio/AccountsContext";
import { buildClasses } from "../utils/buildClasses";

export function Balances() {
  const accounts = useContext(AccountsContext);

  let assetTotal = 0;
  let liabilityTotal = 0;
  let netTotal = 0;

  for (const account of Array.from(accounts.values())) {
    if (!account.current_balance) {
      continue;
    }
    if (account.current_balance > 0) {
      assetTotal += account.current_balance;
    } else if (account.current_balance < 0) {
      liabilityTotal += account.current_balance;
    }
    netTotal += account.current_balance;
  }

  return (
    <div>
      <BalanceTotal label={"Assets"} amount={assetTotal} type={"asset"} />
      <BalanceTotal
        label={"Liabilities"}
        amount={liabilityTotal}
        type={"liability"}
      />
      <BalanceTotal label={"Net"} amount={netTotal} type={"net"} />
    </div>
  );
}

function BalanceTotal(props: {
  label: string;
  amount: number;
  type: "asset" | "liability" | "net";
}) {
  return (
    <div className={buildClasses("flex", "py-5")}>
      <div className={buildClasses("w-20", "text-lg", "font-semibold")}>
        {props.label}
      </div>
      <div
        className={buildClasses(
          {
            switch: props.type,
            cases: new Map([
              ["asset", buildClasses("text-green")],
              ["liability", buildClasses("text-red")],
              ["net", buildClasses("text-purple")],
            ]),
          },
          "w-28",
          "flex",
          "justify-end"
        )}
      >
        <DollarAmount
          n={props.amount}
          includeDecimals={false}
          showPlus={false}
          showMinus={true}
          showDollarSign={true}
          align="right"
          size="lg"
        />
      </div>
    </div>
  );
}
