import { createContext, useContext, useRef, useState } from "react";
import { useClickOutsideElement, useKeyDown } from "../utils/customHooks";
import { getLetterOrNumberFromCode, keyCodes } from "../utils/KeyCodes";
import {
  BreadTransaction,
  getNullFieldValueCriterionEquivalent,
  matchTransactionToRule,
} from "breadcommon";
import { UserContext } from "../firebaseio/UserContext";
import {
  firestoreAddRuleAndUpdateTransactions,
  firestoreUpdateTransaction,
} from "../firebaseio/firestoreIo";
import { FirestoreDocRule } from "breadcommon";
import { RulesContext } from "../firebaseio/RulesContext";
import dayjs from "dayjs";
import { buildClasses } from "../utils/buildClasses";
import { TransactionColumnValueCategory } from "./TransactionColumnValueCategory";
import { TransactionColumnValueReviewed } from "./TransactionColumnValueReviewed";
import { TransactionColumnValueRule } from "./TransactionColumnValueRule";
import { TransactionColumnSpacer } from "./TransactionColumnSpacer";
import { TransactionColumnValueDate } from "./TransactionColumnValueDate";
import { TransactionColumnValueAccount } from "./TransactionColumnValueAccount";
import { TransactionColumnValueOrigin } from "./TransactionColumnValueOrigin";
import { TransactionColumnValueDescription } from "./TransactionColumnValueDescription";
import { TransactionColumnValueAmount } from "./TransactionColumnValueAmount";

export const TransactionRowContext = createContext<{
  transactionSelected: boolean;
  ruleForFieldHighlighting: {
    get: FirestoreDocRule | null;
    set: React.Dispatch<React.SetStateAction<FirestoreDocRule | null>>;
  };
  keyListenerShouldListen: boolean;
}>({
  transactionSelected: false,
  ruleForFieldHighlighting: { get: null, set: () => {} },
  keyListenerShouldListen: false,
});

export function TransactionRow(props: {
  transaction: BreadTransaction;
}): JSX.Element {
  const [transactionSelected, setTransactionSelected] = useState(false);
  const [ruleForFieldHighlighting, setRuleForFieldHighlighting] =
    useState<FirestoreDocRule | null>(null);
  const [isRuleEditorOpen, setIsRuleEditorOpen] = useState<boolean>(false);
  const TransactionRowContainerRef = useRef<HTMLDivElement>(null);
  const categoryDropDownRef = useRef<HTMLInputElement | null>(null);
  const user = useContext(UserContext);
  const rules = useContext(RulesContext);

  const keyListenerShouldListen = transactionSelected && !isRuleEditorOpen;

  useClickOutsideElement(
    TransactionRowContainerRef,
    () => TransactionRowContainerRef.current?.blur(),
    transactionSelected,
    window
  );

  useKeyDown(
    TransactionRowContainerRef,
    keyListenerShouldListen,
    handleKeyDown
  );

  const { bestMatchRule, bestMatchRuleIsActive } = matchTransactionToRule(
    props.transaction,
    rules
  );

  let newRuleOrderPosition = 0;
  rules.forEach(
    (rule) =>
      (newRuleOrderPosition = Math.min(
        rule.order_position - 1,
        newRuleOrderPosition
      ))
  );
  function createRuleFromTransactionWithoutId(
    categoryIdAction: string
  ): Omit<FirestoreDocRule, "id"> {
    return {
      criteria: {
        account_id: props.transaction.account_id,
        merchant_name:
          props.transaction.merchant?.name ??
          (props.transaction.type !== null
            ? null
            : getNullFieldValueCriterionEquivalent()),
        description:
          props.transaction.merchant || props.transaction.type
            ? null
            : props.transaction.description ??
              getNullFieldValueCriterionEquivalent(), // only include description if there is no merchant
        partial_description: null,
        type:
          props.transaction.type ??
          (props.transaction.merchant !== null
            ? null
            : getNullFieldValueCriterionEquivalent()),
        subtype:
          props.transaction.subtype ??
          (props.transaction.merchant !== null
            ? null
            : getNullFieldValueCriterionEquivalent()),
      },
      action: {
        category_id: categoryIdAction,
      },
      created_timestamp_secs: dayjs().unix(),
      order_position: newRuleOrderPosition,
    };
  }

  function scrollIntoView(div: HTMLDivElement) {
    div.scrollIntoView({
      block: "center",
      inline: "nearest",
      behavior: "smooth",
    });
  }

  function markReviewed(): void {
    firestoreUpdateTransaction(
      user.uid,
      props.transaction.id,
      {
        manual_data: { reviewed: true },
      },
      null
    );
  }

  function selectRowAbove() {
    const prevSibling =
      TransactionRowContainerRef.current?.previousElementSibling;
    if (transactionSelected && prevSibling instanceof HTMLDivElement) {
      TransactionRowContainerRef.current?.blur();
      const siblingDiv = prevSibling as HTMLDivElement;
      siblingDiv.focus();
      scrollIntoView(siblingDiv);
    }
  }

  function selectRowBelow() {
    const nextSibling = TransactionRowContainerRef.current?.nextElementSibling;
    if (transactionSelected && nextSibling instanceof HTMLDivElement) {
      TransactionRowContainerRef.current?.blur();
      const siblingDiv = nextSibling as HTMLDivElement;
      siblingDiv.focus();
      scrollIntoView(siblingDiv);
    }
  }

  function createNewRule(categoryIdAction: string): void {
    if (!bestMatchRuleIsActive) {
      firestoreAddRuleAndUpdateTransactions(
        user.uid,
        createRuleFromTransactionWithoutId(categoryIdAction)
      );
    }
  }

  function handleKeyDown(event: KeyboardEvent): void {
    switch (event.code) {
      case keyCodes.ENTER:
        if (event.repeat) return;
        if (props.transaction.categoryId) {
          markReviewed();
        }
        if (event.shiftKey) {
          selectRowAbove();
        } else {
          selectRowBelow();
        }
        return;
      case keyCodes.UPARROW:
        event.preventDefault();
        const prevSibling =
          TransactionRowContainerRef.current?.previousElementSibling;
        if (transactionSelected && prevSibling instanceof HTMLDivElement) {
          selectRowAbove();
        }
        break;
      case keyCodes.DOWNARROW:
        event.preventDefault();
        const nextSibling =
          TransactionRowContainerRef.current?.nextElementSibling;
        if (transactionSelected && nextSibling instanceof HTMLDivElement) {
          selectRowBelow();
        }
        break;
      case keyCodes.ESC:
        event.preventDefault();
        if (event.repeat) return;
        if (transactionSelected) {
          TransactionRowContainerRef.current?.blur();
        }
        break;
      default:
        if (
          !(event.ctrlKey || event.metaKey) &&
          getLetterOrNumberFromCode(event.code) !== null
        ) {
          categoryDropDownRef.current?.focus();
        }
        break;
    }
  }

  return (
    <TransactionRowContext.Provider
      value={{
        transactionSelected: transactionSelected,
        ruleForFieldHighlighting: {
          get: ruleForFieldHighlighting,
          set: setRuleForFieldHighlighting,
        },
        keyListenerShouldListen: keyListenerShouldListen,
      }}
    >
      <div
        ref={TransactionRowContainerRef}
        // onClick={() => setTransactionSelected(true)}
        className={buildClasses(
          {
            if: transactionSelected,
            then: buildClasses("bg-purple-100"),
            else: buildClasses("bg-surface", "hover:bg-surface-300"),
          },
          "h-10",
          "flex",
          "items-center",
          "justify-center",
          "px-2",
          "py-1",
          "border-t-tiny",
          "border-t-surface-600",
          "first:border-t-0",
          "outline-none"
        )}
        onFocus={() => setTransactionSelected(true)}
        onBlur={() => setTransactionSelected(false)}
        tabIndex={0}
      >
        <TransactionColumnValueDate date={props.transaction.date} />
        <TransactionColumnSpacer />
        <TransactionColumnValueAccount
          accountId={props.transaction.account_id}
        />
        <TransactionColumnSpacer />
        <TransactionColumnValueOrigin
          merchant={props.transaction.merchant}
          type={props.transaction.type}
          subtype={props.transaction.subtype}
        />
        <TransactionColumnSpacer />
        <TransactionColumnValueDescription
          description={props.transaction.description}
        />
        <TransactionColumnSpacer />
        <TransactionColumnValueCategory
          transactionId={props.transaction.id}
          categoryId={props.transaction.categoryId}
          createNewRule={createNewRule}
          focusTransaction={() => TransactionRowContainerRef.current?.focus()}
          selectPreviousTransaction={selectRowAbove}
          selectNextTransaction={selectRowBelow}
          saveDropDownRef={(inputRef) => {
            categoryDropDownRef.current = inputRef.current;
          }}
        ></TransactionColumnValueCategory>
        <TransactionColumnSpacer />
        <TransactionColumnValueRule
          transaction={props.transaction}
          activeRule={bestMatchRuleIsActive ? bestMatchRule : null}
          createRuleFromTransactionWithoutId={
            createRuleFromTransactionWithoutId
          }
          ruleEditorIsOpen={isRuleEditorOpen}
          openRuleEditor={() => setIsRuleEditorOpen(true)}
          closeRuleEditor={() => setIsRuleEditorOpen(false)}
        />
        <TransactionColumnSpacer />
        <TransactionColumnValueReviewed
          transaction={props.transaction}
        ></TransactionColumnValueReviewed>
        <TransactionColumnSpacer />
        <TransactionColumnValueAmount amount={props.transaction.amount} />
      </div>
    </TransactionRowContext.Provider>
  );
}
