import { buildClasses } from "../utils/buildClasses";
import styles from "./RyeIcon.module.scss";

// "name" is the name of the icon at https://fonts.google.com/icons
// TODO: take in filled, size, color, etc. in props and set style dynamically
export function RyeIcon({
  name,
  size = "md",
  fill = false,
  className = "",
  onClick = undefined,
  tooltip = undefined,
}: {
  name: string;
  size?: "sm" | "md" | "lg" | "xl" | "custom";
  fill?: boolean;
  className?: string;
  onClick?: undefined | ((args: any) => void);
  tooltip?: string | undefined;
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        "material-symbols-rounded",
        {
          if: fill,
          then: styles.fill,
        },
        {
          switch: size,
          cases: new Map([
            ["sm", "text-[1.1rem]"],
            ["md", "text-[1.33rem]"],
            ["lg", "text-[1.56rem]"],
            ["xl", "text-[1.79rem]"],
            ["custom", ""], // For "custom" don't override a custom size that the user presumably set via className
          ]),
        },
        className
      )}
      onClick={onClick}
      title={tooltip}
    >
      {name}
    </div>
  );
}
