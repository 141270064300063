import { buildClasses } from "../utils/buildClasses";

export function RuleHighlighter({
  ruleHighlight,
  color = "purple",
  align = "left",
  children,
}: {
  ruleHighlight: boolean;
  color: "purple" | "green";
  align?: "left" | "right";
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        {
          if: ruleHighlight,
          then: buildClasses({
            switch: color,
            cases: new Map([
              [
                "purple",
                buildClasses(
                  "border-purple",
                  "shadow-glow",
                  "shadow-purple/20"
                ),
              ],
              [
                "green",
                buildClasses("border-green", "shadow-glow", "shadow-green/20"),
              ],
            ]),
          }),
          else: buildClasses("border-transparent", "shadow-none"),
        },
        {
          switch: align,
          cases: new Map([
            ["left", "justify-start"],
            ["right", "justify-end"],
          ]),
        },
        "w-full",
        "h-full",
        "flex",
        "items-center",
        "rounded-md",
        "border-tiny"
      )}
    >
      {children}
    </div>
  );
}
