import { useContext, useState } from "react";
import { AccountSettingsModal } from "./AccountSettingsModal";
import { BreadAccount } from "breadcommon";
import { InstitutionsContext } from "../firebaseio/InstitutionsContext";
import { useGetInstitutionFromAccount } from "../utils/customHooks";
import { InstitutionLogo } from "../common/InstitutionLogo";
import { DollarAmount } from "../common/DollarAmount";
import { buildClasses } from "../utils/buildClasses";
import { RyeButton } from "../rye/RyeButton";

export function AccountRow(props: { account: BreadAccount }): JSX.Element {
  const [accountSettingsModalIsOpen, setAccountSettingsModalIsOpen] =
    useState<boolean>(false);
  const institutions = useContext(InstitutionsContext);

  let institution = useGetInstitutionFromAccount(props.account);
  if (
    props.account.institution_id !== null &&
    institutions.has(props.account.institution_id)
  ) {
    institution = institutions.get(props.account.institution_id);
  }

  return (
    <div
      className={buildClasses(
        "w-full",
        "flex",
        "items-center",
        "py-4",
        "pl-4",
        "group/accountrow"
      )}
    >
      <InstitutionLogo institution={institution} size={50}></InstitutionLogo>
      <div className={buildClasses("w-7", "flex-grow-0", "flex-shrink-0")} />
      <div
        className={buildClasses(
          "w-12",
          "min-w-12",
          "max-w-36",
          "flex-grow",
          "text-on-surface-500"
        )}
      >
        {institution?.name}
      </div>
      <FieldSpacer />
      <div
        className={buildClasses(
          "w-12",
          "min-w-12",
          "max-w-24",
          "flex-grow",
          "text-on-surface-500"
        )}
      >
        {props.account.type}
      </div>
      <FieldSpacer />
      <div className={buildClasses("w-16", "min-w-16", "flex")}>
        {props.account.mask ? (
          <div
            className={buildClasses(
              "bg-on-surface-200",
              "text-sm",
              "px-2",
              "py-px",
              "rounded-md",
              "text-on-surface-500"
            )}
          >
            {"..." + props.account.mask}
          </div>
        ) : null}
      </div>
      <FieldSpacer />
      <div
        className={buildClasses(
          "w-16",
          "min-w-16",
          "flex-grow",
          "font-medium",
          "text-lg"
        )}
      >
        {props.account.name}
      </div>
      <FieldSpacer />
      <div
        className={buildClasses(
          "w-28",
          "min-w-28",
          "flex",
          "ml-auto",
          "justify-end"
        )}
      >
        <DollarAmount
          n={props.account.current_balance ? props.account.current_balance : 0}
          includeDecimals={false}
          showPlus={false}
          showMinus={true}
          showDollarSign={true}
          align="right"
        />
      </div>
      <FieldSpacer />
      <div
        className={buildClasses("invisible", "group-hover/accountrow:visible")}
      >
        <RyeButton
          icon={"more_vert"}
          size="sm"
          variant="transparent"
          vibe="subdued"
          onClick={() => {
            setAccountSettingsModalIsOpen(true);
          }}
        ></RyeButton>
      </div>
      {accountSettingsModalIsOpen ? (
        <AccountSettingsModal
          isOpen={accountSettingsModalIsOpen}
          setIsOpen={setAccountSettingsModalIsOpen}
          initialAccount={props.account}
        ></AccountSettingsModal>
      ) : null}
    </div>
  );
}

function FieldSpacer() {
  return <div className={buildClasses("w-8", "flex-shrink-0")} />;
}
